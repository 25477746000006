import React, { Fragment } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routing/AppRoutes";
import "./style/App.css";
import "./style/Error.css";
import "./style/Landing.css";

function App() {
  return (
    <Fragment>
      <Router>
        <AppRoutes />
      </Router>
    </Fragment>
  );
}

export default App;
