import React, { useState } from "react";
import Swing from "react-reveal/Swing";
const GameContainer = ({ imgSrc, url }) => {
  const [isWiggleTime, setWiggle] = useState(false);
  const hoverEffect = () => {
    if (!isWiggleTime) {
      setWiggle(true);
      setTimeout(() => {
        setWiggle(false);
      }, 1000);
    }
  };
  return (
    <Swing when={isWiggleTime}>
      <a href={url}>
        <div
          className="game-container card-shadow"
          onMouseEnter={() => hoverEffect()}
        >
          {imgSrc !== undefined && <img src={imgSrc} alt="game" />}
        </div>
      </a>
    </Swing>
  );
};
export default GameContainer;
