import React from "react";
import { Route, Routes } from "react-router-dom";

import Landing from "../pages/Landing";
import Error from "../pages/Error";

import { LayoutContextProvider } from "./LayoutContext";

const AppRoutes = () => {
  return (
    <>
      <LayoutContextProvider>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/error" element={<Error />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </LayoutContextProvider>
    </>
  );
};

export default AppRoutes;
