import React from "react";
import GameContainer from "../components/GameContainer";
import geoGameImg from "../media/geoGameWithTitle.jpg";
import comingSoonImg from "../media/comingSoon.jpg";
const Landing = () => {
  return (
    <div className="landing-container">
      <div className="game-list-container">
        <GameContainer
          imgSrc={geoGameImg}
          url={"https://geogame.norbertlaszlo.com"}
        />
        <GameContainer imgSrc={comingSoonImg} url={""} />
      </div>
    </div>
  );
};
export default Landing;
